import {EMPLOYEES, DO_APPRAISAL, HRD} from '@constants/internal-route.constant'
import {IAppRoutes} from '@interface/common.interface'
import {lazy} from 'react'

export const HrdRoutes: IAppRoutes = {
  link: HRD,
  childrens: [
    {
      link: EMPLOYEES,
      element: lazy(() => import('@modules/hrd/Employees')),
    },
    {
      link: DO_APPRAISAL,
      element: lazy(() => import('@modules/hrd/do-appraisal')),
    },
  ],
}
