import Icon from '@components/Icon'
import {
  HRD,
  APPRAISAL_REPORT,
  CANDIDATES,
  DO_APPRAISAL,
  EMPLOYEES,
  JOBS,
  RECRUITMENT,
  REPORTS,
  EMP_PDS_REPORT,
  PM,
  PROJECT,
  WBS,
  KANBAN,
  PROJECT_REPORT,
  TASK_REPORT,
  REQUISITIONS,
} from '@constants/internal-route.constant'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MenuItem} from './MenuItem'

export function MenuInner() {
  return (
    <>
      <MenuInnerWithSub
        fontIcon='bi-person-vcard'
        title='Recruitment'
        titleHover='eRecruitment'
        to={RECRUITMENT}
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuItem to={REQUISITIONS} fontIcon='bi-person-lines-fill' title='Requisitions' />
        <MenuItem to={JOBS} fontIcon='bi-briefcase' title='Jobs' />
        <MenuItem to={CANDIDATES} fontIcon='bi-person-bounding-box' title='Candidates' />
        {/* PAGES */}
      </MenuInnerWithSub>

      <MenuInnerWithSub
        fontIcon='bi-journals'
        title='HRD'
        titleHover='Human Resource Data'
        to={HRD}
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuItem to={EMPLOYEES} fontIcon='bi-person-vcard' title='Employees' />
        <MenuItem to={DO_APPRAISAL} fontIcon='bi-journal-text' title='Appraisals' />
        {/* PAGES */}
      </MenuInnerWithSub>

      <MenuInnerWithSub
        fontIcon='bi-stickies'
        title='PM'
        titleHover='Project Management'
        to={PM}
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuItem to={PROJECT} fontIcon='bi-person-vcard' title='Project' />
        <MenuItem to={WBS} fontIcon='bi-bar-chart-steps' title='Work Breakdown Structure - WBS' />
        <MenuItem to={KANBAN} fontIcon='bi-kanban' title='Kanban Board' />
        {/* PAGES */}
      </MenuInnerWithSub>

      {/* Reports  */}
      <MenuInnerWithSub
        fontIcon='bi-graph-up-arrow'
        title='Reports'
        titleHover='Reports'
        to={REPORTS}
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuItem to={APPRAISAL_REPORT} fontIcon='bi-graph-up-arrow' title='Appraisal Report' />
        <MenuItem to={EMP_PDS_REPORT} fontIcon='bi-journal' title='Employee PDS Report' />
        <MenuItem to={PROJECT_REPORT} fontIcon='bi-clipboard-data' title='Project Report' />
        <MenuItem to={TASK_REPORT} fontIcon='bi-list-task' title='Task Report' />
      </MenuInnerWithSub>
    </>
  )
}
