import { PM, PROJECT, WBS, KANBAN } from '@constants/internal-route.constant'
import { IAppRoutes } from '@interface/common.interface'
import { lazy } from 'react'

export const PmRoutes: IAppRoutes = {
  link: PM,
  childrens: [
    {
      link: PROJECT,
      element: lazy(() => import('@modules/pm/project')),
    },
    {
      link: WBS,
      element: lazy(() => import('@modules/pm/wbs')),
    },
    {
      link: `${WBS}/:id`,
      element: lazy(() => import('@modules/pm/wbs')),
    },
    {
      link: KANBAN,
      element: lazy(() => import('@modules/pm/kanban')),
    },
    {
      link: `${KANBAN}/:id`,
      element: lazy(() => import('@modules/pm/kanban')),
    },
  ],
}
