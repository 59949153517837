import {CANDIDATES, JOBS, RECRUITMENT, REQUISITIONS} from '@constants/internal-route.constant'
import {IAppRoutes} from '@interface/common.interface'
import {lazy} from 'react'

export const RecruitmentRoutes: IAppRoutes = {
  link: RECRUITMENT,
  childrens: [
    {
      link: REQUISITIONS,
      element: lazy(() => import('@modules/recruitment/Requisition')),
    },
    {
      link: JOBS,
      element: lazy(() => import('@modules/recruitment/Jobs')),
    },
    {
      link: CANDIDATES,
      element: lazy(() => import('@modules/recruitment/Candidates')),
    },
  ],
}
