/* eslint-disable jsx-a11y/anchor-is-valid */
import Icon from '@components/Icon'
import {FC} from 'react'
import {Link} from 'react-router-dom'

const Dropdown2: FC = () => {
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-450px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content fs-6 text-dark fw-bolder px-3 py-4 text-center'>
          Quick Settings
        </div>
      </div>

      <div className='separator mb-2 opacity-75'></div>

      <div className='row mb-2'>
        <div className='col-md-6'>
          <div className='menu-item px-3'>
            <Link to='/settings/department' className='menu-link px-3'>
              <Icon icon='foundation' size={25} variants='outlined' />
              &nbsp;Department
            </Link>
          </div>
          <div className='menu-item px-3'>
            <Link to='/settings/designation' className='menu-link px-3'>
              <Icon icon='school' size={25} variants='outlined' />
              &nbsp;Designation
            </Link>
          </div>
          <div className='menu-item px-3'>
            <Link to='/settings/emp-type' className='menu-link px-3'>
              <Icon icon='badge' size={25} variants='outlined' />
              &nbsp;Employee Type
            </Link>
          </div>
          <div className='menu-item px-3'>
            <Link to='/settings/grades' className='menu-link px-3'>
              <Icon icon='grade' size={25} variants='outlined' />
              &nbsp;Grades
            </Link>
          </div>
        </div>
        <div className='col-md-6'>
          <div className='menu-item px-3'>
            <Link to='/settings/shifts' className='menu-link px-3'>
              <Icon icon='filter_tilt_shift' size={25} variants='outlined' />
              &nbsp;Shifts
            </Link>
          </div>
          <div className='menu-item px-3'>
            <Link to='/settings/appraisal-params' className='menu-link px-3'>
              <Icon icon='category' size={25} variants='outlined' />
              &nbsp;Appraisal Params
            </Link>
          </div>
          <div className='menu-item px-3'>
            <Link to='/settings/job-description' className='menu-link px-3'>
              <Icon icon='work_history' size={25} variants='outlined' />
              &nbsp;Job Description Setup
            </Link>
          </div>
          <div className='menu-item px-3'>
            <Link to='/settings/project-category' className='menu-link px-3'>
              <Icon icon='task' size={25} variants='outlined' />
              &nbsp;Project Category
            </Link>
          </div>
        </div>
      </div>

      <div className='separator opacity-75'></div>

      {/* <div className='row mb-2'>
        <div className='col-md-3'></div>
        <div className='col-md-6'>
          <div className='menu-item'>
            <Link to='/settings/talent-hunt' className='menu-link px-3 fs-3'>
              <Icon icon='emoji_objects' size={25} variants='outlined' />
              &nbsp;Talent Hunt
            </Link>
          </div>
        </div>
        <div className='col-md-3'></div>
      </div> */}
    </div>
  )
}

export {Dropdown2}
