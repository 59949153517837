import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '_metronic/i18n/i18nProvider'
import {LayoutSplashScreen} from 'layouts/SplashScreen'
import {MasterInit} from 'layouts/MasterInit'
import {LayoutProvider} from 'layouts/core'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
      retryDelay: 1000,
    },
  },
})

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
          <LayoutProvider>
            <Outlet />
            <MasterInit />
          </LayoutProvider>
        </I18nProvider>
      </Suspense>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export {App}
