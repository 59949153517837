import {LOGIN} from '@constants/internal-route.constant'
import {IAppRoutes} from '@interface/common.interface'
import {lazy} from 'react'

export const AuthRoutes: IAppRoutes[] = [
  {
    link: 'login',
    element: lazy(() => import('@modules/auth/Login')),
  },
  {
    link: 'sso-login',
    element: lazy(() => import('@modules/auth/AppLogin')),
  },
  {
    link: '',
    redirect: LOGIN,
  },
]
