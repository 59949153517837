import { FC } from 'react';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '@services/helper/common.helper';

const Unauthorized401: FC = () => {
  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-center flex-column-fluid p-10'>
        <img
          src={toAbsoluteUrl('/media/misc/outdoor.png')}
          alt=''
          className='mw-100 mb-10 h-lg-450px'
        />
        {/* begin::Message */}
        <h1 className='fw-bold mb-10' style={{ color: '#A3A3C7' }}>
          Sorry! You are not allowed to access here.
        </h1>
        {/* end::Message */}
        {/* begin::Link */}
        <Link to='/' className='btn btn-primary'>
          Return
        </Link>
        {/* end::Link */}
      </div>
    </div>
  );
};

export { Unauthorized401 };
