import {ENV} from 'config/ENV.config'

export const setWindowTitle = (title: string = null) => {
  const t = title || window.location.pathname?.split('/')?.slice(1)?.join(' - ')
  document.title = t.charAt(0).toUpperCase() + t.slice(1)
}

export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname

export const getServerFile = (fileUrl) => {
  if (fileUrl) {
    return ENV.apiEndpoint + '/' + fileUrl
  }
  return ''
}

export const getUrlFromJson = (jsonStr) => {
  if (jsonStr) {
    return ENV.apiEndpoint + '/' + JSON.parse(jsonStr).url
  }
  return ''
}

export const getUrlFromJsonArray = (jsonArrStr) => {
  if (jsonArrStr) {
    return ENV.apiEndpoint + '/' + JSON.parse(jsonArrStr)[0].url
  }
  return ''
}

export const getCurrentMonth = () => {
  var month = new Date().getMonth() // js month start with 0
  if (month < 10) return '0' + month
  else return month
}

export const getCurrentJavaMonth = () => {
  var month = new Date().getMonth() + 1 // js month start with 0, but java month start with 1
  if (month < 10) return '0' + month
  else return month
}

export const getGenderOptions = [
  {type: 'Male', value: 'MALE'},
  {type: 'Female', value: 'FEMALE'},
  {type: 'Other', value: 'OTHER'},
]

export const getSocialMediaOpts = [
  {type: 'Linkedin', value: 'LINKEDIN'},
  {type: 'Facebook', value: 'FACEBOOK'},
  {type: 'Stackoverflow', value: 'STACKOVERFLOW'},
  {type: 'HackerRank', value: 'HACKERRANK'},
  {type: 'Github', value: 'GITHUB'},
  {type: 'Website', value: 'WEBSITE'},
  {type: 'Other', value: 'OTHER'},
]

export const priorityOptions = [
  {name: 'Low', value: '1'},
  {name: 'Medium', value: '2'},
  {name: 'High', value: '3'},
  {name: 'Critical', value: '4'},
]

export const employeeFileTypes = [
  {name: 'CV/Resume', value: 'CV'},
  {name: 'NID/Citizen ID', value: 'NID'},
  {name: 'Passport', value: 'PASSPORT'},
  {name: 'Driving License', value: 'DRIVING_LC'},
  {name: 'Birth Certificate', value: 'BIRTH_CERT'},
]

export const projectFileTypes = [
  {name: 'Design Documents', value: 'DESIGN_DOC'},
  {name: 'SRS', value: 'SRS'},
  {name: 'User Manual', value: 'USER_MANUAL'},
  {name: 'Technical Documnet', value: 'TECH_DOC'},
  {name: 'Use Case Diagram', value: 'UC_DIAGRAM'},
  {name: 'UML Diagram', value: 'UML_DIAGRAM'},
  {name: 'ER Diagram', value: 'ER_DIAGRAM'},
  {name: 'Others', value: 'OTHERS'},
]

export const monthOptions = [
  {name: 'January', value: '01'},
  {name: 'February', value: '02'},
  {name: 'March', value: '03'},
  {name: 'April', value: '04'},
  {name: 'May', value: '05'},
  {name: 'June', value: '06'},
  {name: 'July', value: '07'},
  {name: 'August', value: '08'},
  {name: 'September', value: '09'},
  {name: 'October', value: '10'},
  {name: 'November', value: '11'},
  {name: 'December', value: '12'},
]

export const monthShortOptions = [
  {name: 'Jan', value: '01'},
  {name: 'Feb', value: '02'},
  {name: 'Mar', value: '03'},
  {name: 'Apr', value: '04'},
  {name: 'May', value: '05'},
  {name: 'Jun', value: '06'},
  {name: 'Jul', value: '07'},
  {name: 'Aug', value: '08'},
  {name: 'Sep', value: '09'},
  {name: 'Oct', value: '10'},
  {name: 'Nov', value: '11'},
  {name: 'Dec', value: '12'},
]

export const calcDuration = (inTime: string, outTime: string) => {
  let inTimeArr = inTime.split(' ')
  let outTimeArr = outTime.split(' ')
  let timeFormat = 0
  if (inTimeArr[1] === 'PM') {
    timeFormat = 12
  }
  if (outTimeArr[1] === 'PM') {
    timeFormat = 12
  }

  let inHour = parseInt(inTimeArr[0].split(':')[0], 10)
  let inMin = parseInt(inTimeArr[0].split(':')[1], 10)
  let outHour = parseInt(outTimeArr[0].split(':')[0], 10)
  let outMin = parseInt(outTimeArr[0].split(':')[1], 10)

  let durationHour = inHour - outHour + timeFormat
  if (durationHour < 0) durationHour = durationHour * -1

  return (durationHour < 10 ? durationHour : durationHour) + ':00' // HOUR:MINUTE
}
