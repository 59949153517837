/* eslint-disable react/jsx-no-target-blank */
import {DASHBOARD, NOTES, TODOS} from '@constants/internal-route.constant'
import {AsideMenuItem} from './AsideMenuItem'
import {ROUTE_CODES} from '@constants/route-codes.constant'
import {useAuth} from '@context/Auth'
import {FormattedMessage} from 'react-intl'

const sideMenus = [
  {
    to: DASHBOARD,
    title: 'MENU.DASHBOARD',
    icon: 'bi-columns-gap',
    route_code: ROUTE_CODES.DASHBOARD,
  },
  {
    to: TODOS,
    title: 'MENU.TODOS',
    icon: 'bi-list-task',
    route_code: ROUTE_CODES.TODOS_CRUD,
  },
  {
    to: NOTES,
    title: 'MENU.NOTES',
    icon: 'bi-journals',
    route_code: ROUTE_CODES.NOTES_CRUD,
  },
]

export const AsideMenuMain = () => {
  const {
    userInfo: {features},
  } = useAuth()

  return (
    <>
      {/* <AsideMenuItem
        to={DASHBOARD}
        title='Dashboard'
        fontIcon='bi-columns-gap fs-2'
        className='py-2'
      />

      <AsideMenuItem to={TODOS} title='Todos' fontIcon='bi-list-task fs-2' className='py-2' />
      <AsideMenuItem to={NOTES} title='Notes' fontIcon='bi-journals fs-2' className='py-2' /> */}

      {sideMenus.map((sm) =>
        features?.findIndex((f) => f?.code === sm.route_code) >= 0 ? (
          <AsideMenuItem
            key={sm?.route_code}
            to={sm.to}
            title={<FormattedMessage id={sm.title} />}
            fontIcon={`${sm.icon} fs-2`}
            className='py-2'
          />
        ) : null
      )}

      {/* <AsideMenuItemWithSubMain
        to='/crafted/pages'
        title='Crafted'
        fontIcon='bi-file-text'
        bsTitle='Crafted'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/profile/overview'
            title='Overview'
            bsTitle='Overview'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/pages/profile/projects'
            title='Projects'
            bsTitle='Projects'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            bsTitle='Campaigns'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            bsTitle='Documents'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
            bsTitle='Connections'
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
            bsTitle='Horizontal'
          />
          <AsideMenuItem
            to='/crafted/pages/wizards/vertical'
            title='Vertical'
            bsTitle='Vertical'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/accounts' title='Accounts' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/account/overview'
            title='Overview'
            hasBullet={true}
            bsTitle='Overview'
          />
          <AsideMenuItem
            to='/crafted/account/settings'
            title='Settings'
            hasBullet={true}
            bsTitle='Settings'
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/widgets' title='Widgets' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/widgets/lists'
            title='Lists'
            bsTitle='Lists'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/widgets/statistics'
            title='Statistics'
            bsTitle='Statistics'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/widgets/charts'
            title='Charts'
            bsTitle='Charts'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/widgets/mixed'
            title='Mixed'
            bsTitle='Mixed'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/widgets/tables'
            title='Tables'
            bsTitle='Tables'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/widgets/feeds'
            title='Feeds'
            bsTitle='Feeds'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/apps/chat' title='Chat' hasBullet={true}>
          <AsideMenuItem
            to='/apps/chat/private-chat'
            title='Private Chat'
            bsTitle='Private Chat'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/apps/chat/group-chat'
            title='Group Chart'
            bsTitle='Group Chart'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/apps/chat/drawer-chat'
            title='Drawer Chart'
            bsTitle='Drawer Chart'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='/error' title='Errors' hasBullet={true}>
          <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
          <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
        </AsideMenuItemWithSub>
        <AsideMenuItem
          to='/apps/user-management/users'
          title='User management'
          hasBullet={true}
          bsTitle='User management'
        />
      </AsideMenuItemWithSubMain>

      <AsideMenuItemWithSubMain
        to='/builder'
        title='Resources'
        bsTitle='Resources'
        fontIcon='bi-gear'
      >
        <AsideMenuItem to='/builder' title='Layout builder' fontIcon='bi-layers fs-3' />
        <AsideMenuItem
          to={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
          outside={true}
          title={`Changelog ${process.env.REACT_APP_VERSION}`}
          fontIcon='bi-card-text fs-3'
        />
      </AsideMenuItemWithSubMain> */}
    </>
  )
}
