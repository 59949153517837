import {
  DEPARTMENT,
  DESIGNATION,
  SETTINGS,
  JOB_DESCRIPTION,
  APPRAISAL_PARAMS,
  EMP_TYPE,
  GRADES,
  SHIFTS,
  TALENT_HUNT,
  PROJECT_CATEGORY,
} from '@constants/internal-route.constant'
import {IAppRoutes} from '@interface/common.interface'
import {lazy} from 'react'

export const SettingsRoutes: IAppRoutes = {
  link: SETTINGS,
  childrens: [
    {
      link: DEPARTMENT,
      element: lazy(() => import('@modules/settings/department')),
    },
    {
      link: DESIGNATION,
      element: lazy(() => import('@modules/settings/designation')),
    },
    {
      link: EMP_TYPE,
      element: lazy(() => import('@modules/settings/emp-type')),
    },
    {
      link: GRADES,
      element: lazy(() => import('@modules/settings/grades')),
    },
    {
      link: SHIFTS,
      element: lazy(() => import('@modules/settings/shifts')),
    },
    {
      link: APPRAISAL_PARAMS,
      element: lazy(() => import('@modules/settings/appraisal-params')),
    },
    {
      link: JOB_DESCRIPTION,
      element: lazy(() => import('@modules/settings/job-description')),
    },
    {
      link: PROJECT_CATEGORY,
      element: lazy(() => import('@modules/settings/project-category')),
    },
    {
      link: TALENT_HUNT,
      element: lazy(() => import('@modules/settings/talent-hunt')),
    },
  ],
}
