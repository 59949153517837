import {ACCESS_TOKEN, COMPANY_ID, USER_INFO} from '@constants/auth.constant'
import {ICompany, IUserInfo} from '@interface/auth.interface'
import {AuthService} from '@services/api/Auth.service'
import {disableSplashScreen, enableSplashScreen} from '@services/helper/splashScreen.helper'
import {LocalStorageService} from '@services/utils/localStorage.service'
import {createContext, Dispatch, SetStateAction, useContext, useEffect, useState} from 'react'
import {isExpiredToken} from 'utility/jwt'
import * as authHelper from '@services/helper/auth.helper'
import {ENV} from 'config/ENV.config'

type AuthContextProps = {
  isAuthenticated: boolean
  isChecking: boolean
  saveAuth: (token: string | null, uid: string | null, cid: string | null) => void
  userInfo: IUserInfo | null
  companyInfo: ICompany | null
  setUserInfo: Dispatch<SetStateAction<IUserInfo | null>>
  logout: () => void
}

const isValidToken = () => {
  const accessToken = LocalStorageService.get(ACCESS_TOKEN)
  if (!accessToken) return false
  return !isExpiredToken(accessToken)
}

const initAuthContextPropsState = {
  isAuthenticated: false,
  isChecking: true,
  saveAuth: () => {},
  userInfo: authHelper.getAuth()?.uInfo,
  companyInfo: null,
  setUserInfo: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider = ({children}) => {
  const initAuth = authHelper.getAuth()
  const [isChecking, setIsChecking] = useState<boolean>(true)
  const [userInfo, setUserInfo] = useState<IUserInfo | null>(initAuth?.uInfo)
  const [companyInfo, setcompanyInfo] = useState<ICompany | null>()
  const [isAuthenticated, makeAuthenticated] = useState<boolean>(isValidToken())

  useEffect(() => {
    if (initAuth?.accessToken) checkTokenOnline()
    else setIsChecking(false)
    // eslint-disable-next-line
  }, [])

  // useEffect(() => {
  //   setInterval(() => {
  //     const isValid = isValidToken()
  //     if (!isValid) {
  //       makeAuthenticated(isValid)
  //       logout()
  //     }
  //   }, 5000)
  // }, [])

  const checkTokenOnline = () => {
    setIsChecking(true)
    const productCode = ENV.productCode
    AuthService.verifyToken(productCode)
      .then((resp) => {
        setUserInfo(resp?.body)
        const currentCompany = JSON.parse(resp?.body?.companylist)?.find(
          (item: ICompany) => item?.companyId == LocalStorageService.get(COMPANY_ID)
        )
        setcompanyInfo(currentCompany)
        LocalStorageService.set(USER_INFO, resp.body)
        makeAuthenticated(true)
      })
      .catch(() => logout())
      .finally(() => setIsChecking(false))
  }

  const saveAuth = (token: string | null, uid: string | null, cid: string | null) => {
    if (token && uid) {
      authHelper.setAuth(token, uid, cid)
      checkTokenOnline()
    } else {
      authHelper.removeAuth()
      makeAuthenticated(false)
    }
  }

  const logout = () => {
    saveAuth(null, null, null)
  }

  isChecking ? enableSplashScreen() : disableSplashScreen()

  return (
    <AuthContext.Provider
      value={{isAuthenticated, isChecking, saveAuth, userInfo, companyInfo, setUserInfo, logout}}
    >
      {children}
    </AuthContext.Provider>
  )
}

export {AuthProvider, useAuth}
