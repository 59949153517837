export const HOME = '/'

export const AUTH = '/auth'
export const LOGIN = AUTH + '/login'
export const SSO_LOGIN = AUTH + '/sso-login'

export const COMPANY_LIST = '/company-list'
export const DASHBOARD = '/dashboard'
export const CHATS = '/chats'
export const TODOS = '/todos'
export const NOTES = '/notes'

//recruitment
export const RECRUITMENT = '/recruitment'
export const REQUISITIONS = RECRUITMENT + '/requisitions'
export const JOBS = RECRUITMENT + '/jobs'
export const CANDIDATES = RECRUITMENT + '/candidates'

//hrd
export const HRD = '/hrd'
export const EMPLOYEES = HRD + '/employees'
export const DO_APPRAISAL = HRD + '/do-appraisal'

//project management
export const PM = '/pm'
export const PROJECT = PM + '/project'
export const WBS = PM + '/wbs'
export const KANBAN = PM + '/kanban'
export const TIMELINE = PM + '/timeline'

//settings
export const SETTINGS = '/settings'
export const DEPARTMENT = SETTINGS + '/department'
export const DESIGNATION = SETTINGS + '/designation'
export const EMP_TYPE = SETTINGS + '/emp-type'
export const GRADES = SETTINGS + '/grades'
export const SHIFTS = SETTINGS + '/shifts'
export const JOB_DESCRIPTION = SETTINGS + '/job-description'
export const PROJECT_CATEGORY = SETTINGS + '/project-category'
export const APPRAISAL_PARAMS = SETTINGS + '/appraisal-params'
export const TALENT_HUNT = SETTINGS + '/talent-hunt'

//Reports
export const REPORTS = '/reports'
export const APPRAISAL_REPORT = REPORTS + '/appraisal-report'
export const EMP_PDS_REPORT = REPORTS + '/emp-pds-report'
export const PROJECT_REPORT = REPORTS + '/project-report'
export const TASK_REPORT = REPORTS + '/task-report'
