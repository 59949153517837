import Icon from '@components/Icon'
import {DASHBOARD} from '@constants/internal-route.constant'
import {IUserInfo} from '@interface/auth.interface'
import {LocalStorageService} from '@services/utils/localStorage.service'
import {useNavigate} from 'react-router-dom'

const ChooseCompany = () => {
  const navigate = useNavigate()
  const userInfo: IUserInfo = LocalStorageService.get('userInfo')

  const onCompanyChange = (cid: string) => {
    LocalStorageService.set('cid', cid)
    navigate(DASHBOARD)
  }

  return (
    <div className='card mb-xl-8'>
      <div className='card-body p-0 row g-2'>
        {userInfo?.companies?.map((com) => (
          <div
            className='d-flex align-items-sm-center btn btn-outline text-start'
            onClick={() => onCompanyChange(com?.companyId)}
            key={com?.companyId}
          >
            <div className='symbol symbol-50px me-5'>
              <span className='symbol-label'>
                <Icon icon='apartment' size={30} color='success' className='align-self-center' />
              </span>
            </div>
            <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
              <div className='flex-grow-1 me-2'>
                <a className='text-gray-800 text-hover-primary fs-6 fw-bold'>{com?.companyName}</a>
                <span className='text-muted fw-semibold d-block fs-7'>{com?.roleName}</span>
              </div>
              <span className='badge badge-light fw-bold my-2'></span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ChooseCompany
