import {DASHBOARD, NOTES, TODOS} from '@constants/internal-route.constant'
import {IAppRoutes} from '@interface/common.interface'
import {lazy} from 'react'
import {RecruitmentRoutes} from './recruitment.routes'
import {SettingsRoutes} from './settings.routes'
import {HrdRoutes} from './hrd.routes'
import {PmRoutes} from './pm.routes'
import {ReportRoutes} from './report.routes'

const Error404 = lazy(() => import('pages/errors/ErrorsPage'))

export const AppRouteList: IAppRoutes[] = [
  {
    link: DASHBOARD,
    element: lazy(() => import('pages/dashboard/DashboardWrapper')),
  },
  {
    link: TODOS,
    element: lazy(() => import('@modules/apps/todos')),
  },
  {
    link: NOTES,
    element: lazy(() => import('@modules/apps/notes')),
  },
  {...RecruitmentRoutes},
  {...HrdRoutes},
  {...PmRoutes},
  {...SettingsRoutes},
  {...ReportRoutes},
  {
    link: 'auth/*',
    redirect: DASHBOARD,
  },
  {
    link: '*',
    element: Error404,
  },
]
