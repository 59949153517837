import { REPORTS, APPRAISAL_REPORT, EMP_PDS_REPORT, PROJECT_REPORT, TASK_REPORT } from '@constants/internal-route.constant'
import { IAppRoutes } from '@interface/common.interface'
import { lazy } from 'react'

export const ReportRoutes: IAppRoutes = {
  link: REPORTS,
  childrens: [
    {
      link: APPRAISAL_REPORT,
      element: lazy(() => import('@modules/reports/appraisal-report')),
    },
    {
      link: EMP_PDS_REPORT,
      element: lazy(() => import('@modules/reports/emp-pds-report')),
    },
    {
      link: PROJECT_REPORT,
      element: lazy(() => import('@modules/reports/project-report')),
    },
    {
      link: TASK_REPORT,
      element: lazy(() => import('@modules/reports/task-report')),
    },
  ],
}
