/* eslint-disable jsx-a11y/anchor-is-valid */
import {useAuth} from '@context/Auth'
import {FC, useEffect, useState} from 'react'
import {Languages} from './Languages'
import {FormattedMessage} from 'react-intl'

const HeaderUserMenu: FC = () => {
  const {userInfo, logout} = useAuth()
  const [companies, setCompanies] = useState<any[]>([])

  useEffect(() => {
    if (userInfo && userInfo?.companylist) {
      setCompanies(JSON.parse(userInfo.companylist))
    }
  }, [userInfo])

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src='/media/avatars/blank.png' />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>{userInfo?.fullName}</div>
            <span className='fw-bold text-primary fs-7'>
              {companies.length > 0 ? companies[0]?.packageKey : ''}
            </span>
            <span className='fw-bold text-muted fs-7'>
              {companies.length > 0 ? companies[0]?.companyName : ''}
            </span>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <a href={'https://users.hrboi.com/invoice-list'} target='_blank' className='menu-link px-5'>
          <i className='fa fa-file'></i>&nbsp;{<FormattedMessage id='USER_MENU.MY_BILLING' />}
        </a>
      </div>

      <div className='menu-item px-5'>
        <a href={'https://users.hrboi.com/profile'} target='_blank' className='menu-link px-5'>
          <i className='fa fa-user'></i>&nbsp; {<FormattedMessage id='USER_MENU.MY_PROFILE' />}
        </a>
      </div>

      <Languages />

      <div className='menu-item px-5'>
        <a
          href={'https://users.hrboi.com/change-password'}
          target='_blank'
          className='menu-link px-5'
        >
          <i className='fa fa-key'></i>&nbsp; {<FormattedMessage id='USER_MENU.CHANGE_PASSWORD' />}
        </a>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          <i className='fa fa-sign-out'></i>&nbsp; {<FormattedMessage id='USER_MENU.SIGN_OUT' />}
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
