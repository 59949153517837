export const isBrowser = typeof window !== 'undefined'
export const isServer = typeof window == 'undefined'

type ILocalStorageData = 'userInfo' | 'accessToken' | 'userId' | 'cid'

export const LocalStorageService = {
  set: (key: ILocalStorageData, value: any): void =>
    localStorage.setItem(key, JSON.stringify(value)),
  get: (key: ILocalStorageData): any | null => JSON.parse(localStorage.getItem(key) || 'null'),
  delete: (key: ILocalStorageData): void => localStorage.removeItem(key),
  clear: (): void => localStorage.clear(),
}
