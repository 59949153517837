export const ROUTE_CODES = {
  DASHBOARD: 'DASHBOARD',
  TODOS_CRUD: 'TODOS_CRUD',
  NOTES_CRUD: 'NOTES_CRUD',

  RECRUITMENT: 'RECRUITMENT',
  REQUISITION_CRUD: 'REQUISITION_CRUD',
  JOBS_CRUD: 'JOBS_CRUD',
  CANDIDATE_CRUD: 'CANDIDATE_CRUD',

  HRD: 'HRD',
  EMPLOYEES_CRUD: 'EMPLOYEES_CRUD',
  APPRAISAL_CRUD: 'APPRAISAL_CRUD',

  PM: 'PM',
  PROJECT_CRUD: 'PROJECT_CRUD',
  WBS_CRUD: 'WBS_CRUD',
  KANBAN_CRUD: 'KANBAN_CRUD',

  REPORTS: 'REPORTS',
  APPRAISAL_REPORT: 'APPRAISAL_REPORT',
  EMPLOYEE_PDS_REPORT: 'EMPLOYEE_PDS_REPORT',
  PROJECT_REPORT: 'PROJECT_REPORT',
  TASK_REPORT: 'TASK_REPORT',

  SETTINGS: 'SETTINGS',
  DEPARTMENT_CRUD: 'DEPARTMENT_CRUD',
  DESIGNATION_CRUD: 'DESIGNATION_CRUD',
  EMP_TYPE_CRUD: 'EMP_TYPE_CRUD',
  GRADE_CRUD: 'GRADE_CRUD',
  SHIFT_CRUD: 'SHIFT_CRUD',
  APPRAISAL_PARAMS_CRUD: 'APPRAISAL_PARAMS_CRUD',
  JOB_DESCRIPTION_CRUD: 'JOB_DESCRIPTION_CRUD',
  PROJECT_CATEGORY_CRUD: 'PROJECT_CATEGORY_CRUD',
}
